<template>
  <div class="home">

    <span class="dot">●</span>
    <span class="route-name">首页</span>
    <div style="text-align: center;">
      看上面菜单
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
